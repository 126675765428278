import { createModuleApi, Daily, DailyHomeWork, Page, PageResponse } from 'momai'

const dailyApi = <T>() => createModuleApi<T>('mmdaily')

const dailyWorkApi = <T>() => createModuleApi<T>('mmdailywork')

interface QryDailyWorkParams {
  dailyTime?: string;
  pageInfo: Page;
}

const createQryDailyWorkParams = (): QryDailyWorkParams => ({
  dailyTime: '',
  pageInfo: {
    current: 1,
    size: 10
  }
})

const createQryMyDailyWorkParams = (): QryDailyWorkParams => ({
  pageInfo: {
    current: 1,
    size: 10
  }
})

const save = (daily: Daily) => dailyApi<string>()({
  subUrl: 'save',
  method: 'post',
  params: daily
})

// 查询某一天打卡内容
const qryDailyInfo = (dailyTime: string) => dailyApi<string>()({
  subUrl: 'qryDailyInfo',
  method: 'post',
  params: { dailyTime }
})

// 根据月份查询当月打卡情况
const qryUserDailyWork4Month = (dailyMonth: string) => dailyWorkApi<string>()({
  subUrl: 'qryUserDailyWorkForMonth',
  method: 'post',
  params: { dailyMonth }
})

// 我的打卡分页查询
const qryMyDailyWork = ({ pageInfo }: QryDailyWorkParams) => dailyWorkApi<PageResponse<DailyHomeWork>>()({
  subUrl: 'qryMyDailyWork',
  method: 'post',
  params: { ...pageInfo }
})

// 打卡内容分页查询
const qryDailyWork = ({ dailyTime, pageInfo }: QryDailyWorkParams) => dailyWorkApi<PageResponse<DailyHomeWork>>()({
  subUrl: 'qryDailyWork',
  method: 'post',
  params: { dailyTime, ...pageInfo }
})

// 累计打卡天数
const qryTotalWork = () => dailyWorkApi<string>()({
  subUrl: 'totalWork',
  method: 'post'
})

/**
 * 获取未审批打卡数量
 */
 const getDailyUndoNum = () => dailyWorkApi<number>()({
  subUrl: 'get-daily-undo-num'
})


export {
  save,
  qryDailyInfo,
  qryUserDailyWork4Month,
  qryMyDailyWork,
  qryDailyWork,
  qryTotalWork,
  QryDailyWorkParams,
  createQryDailyWorkParams,
  createQryMyDailyWorkParams,
  getDailyUndoNum
}
