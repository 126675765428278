import { ref, Ref } from 'vue'
import { Toast, Dialog } from 'vant'
import { uploadVideo, uploadBigVedio } from '@/api/universal/file'

export default ({
  uploadDirectory,
  picker,
  uploadFinished = () => {}
}: {
  picker: Ref<HTMLInputElement | null>;
  uploadDirectory: string;
  uploadFinished?: (videoUrl: Ref<string>, coverUrl: Ref<string>, duration: Ref<number>) => void;
}) => {
  const videoUrl = ref('')
  const coverUrl = ref('')
  const duration = ref(0)
  const percentage = ref(0)
  const updatePercentage = (p: number) => {
    percentage.value = p
  }

  return {
    videoUrl,
    coverUrl,
    duration,
    percentage,
    openVideoPicker: () => {
      const pickerValue = picker.value
      if (pickerValue) {
        pickerValue.click()
      }
    },
    videoChange: (e: Event) => {
      const files = (e.target as HTMLInputElement).files
      if (files) {
        const f = files[0]
        if (f) {
          if (uploadDirectory.startsWith('homework-user')) {
            const is30M = f.size / 1024 / 1024 < 30
            if (!is30M) {
              Dialog.alert({
                message: '视频不得超过30M！'
              }).then(() => {
              })
              return
            }
          }
          uploadBigVedio(f, uploadDirectory, (r) => {
            if (r == null) {
              if (picker.value) {
                picker.value.value = ''
              }
              return
            }
            videoUrl.value = r.videoPath
            coverUrl.value = r.coverPath
            duration.value = r.duration
            uploadFinished(videoUrl, coverUrl, duration)
            Toast.success('上传成功')
            if (picker.value) {
              picker.value.value = ''
            }
          }
          )
          // uploadVideo({
          //   video: f,
          //   directory: uploadDirectory,
          //   update: updatePercentage
          // }).then(r => {
          //   videoUrl.value = r.videoPath
          //   coverUrl.value = r.coverPath
          //   duration.value = r.duration
          //   uploadFinished(videoUrl, coverUrl, duration)
          //   if (picker.value) {
          //     picker.value.value = ''
          //   }
          //   Toast.success('上传成功')
          // })
        }
      }
    }
  }
}
