
import { Daily,createDaily } from 'momai'
import { ref } from '@vue/reactivity';
import { api } from '@/api/useAxios'
import useVideoUploader from './useVideoUploader'
import { Toast } from 'vant'
import { save } from '@/api/entity/daily'


export default {
  setup: () => {
      const dailyInfo = ref<Daily>(createDaily())
      const show = ref(false)
      const formatDate = (date:Date)=>{
        return `${date.getFullYear()}${('0'+(date.getMonth() + 1)).substr(-2)}${date.getDate()}`;
      }
      dailyInfo.value.dailyTime= formatDate(new Date)
      const reset = (date:string) => {
        dailyInfo.value = createDaily()
        dailyInfo.value.dailyTime = date
      }
      const qryInfo = () => {
        api.post('/mmdaily/qryDailyInfo', {
          dailyTime: dailyInfo.value.dailyTime
        }).then((r:Daily)  => {
          if(r){
            dailyInfo.value = r
          }else{
            reset(dailyInfo.value.dailyTime)
          }
        })
        
      }
      qryInfo()
      
      return {
        dailyInfo,
        ...useVideoUploader(dailyInfo),
        show,
        minDate: new Date(2021, 0, 1),
        onConfirm:(date:Date) => {
          show.value = false
          dailyInfo.value.dailyTime= formatDate(date)
          qryInfo()
        },
        doSave: ()=> {
          save(dailyInfo.value).then(()  => {
            Toast.success('保存成功')
          })
        }
      }

  }
}
