import { ref, Ref } from 'vue'
import useUploadVideo from '@/utils/useUploadVideo'
import { Daily } from 'momai'

export default (dailyInfo: Ref<Daily>) => {
  const videoUploader = ref<HTMLInputElement | null>(null)
  const uploadDirectory = 'mmdaily-video'
  return {
    videoUploader,
    dailyVideo: useUploadVideo({
      picker: videoUploader,
      uploadDirectory,
      uploadFinished: (videoUrl, coverUrl, duration) => {
        dailyInfo.value.video = videoUrl.value
        dailyInfo.value.videoCover = coverUrl.value
        dailyInfo.value.videoLength = duration.value
      }
    })

  }
}
